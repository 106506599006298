import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Functional component for Information Governance
const InformationGovernance = (props: any) => {
    // State for storing the "involve case" data
    const [involveCase, setInvolveCase] = useState<any>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    // State for storing form data
    const [formData, setFormData] = useState<any>(null);

    // UseEffect to extract form data on component mount
    useEffect(() => {
        const formData = props.data.controls.find((res: any) => {
            return res.type === "submit" ? res : null
        })

        if (formData) {
            setFormData(formData)
        }
    }, [])

    useEffect(() => {
        setIsFetching(props.isFetching)
    }, [props.isFetching])

    // Function to call API and handle redirection
    const callApiAndRedirect = (e: any): void => {
        if (involveCase !== null && involveCase !== "") {
            setIsFetching(true)
            const url = formData.apiEndpoint.replace('api', "");
            props.onSubmit("formSubmit", { url, involveCase })
        } else {
            toast.error("Please select involve case")
        }
    }

    // Function to render layout based on item type
    const __renderLayout = (items: any, index: any) => {
        let itemsToRender = [];

        switch (items.type) {
            case "heading2":
                itemsToRender.push(
                    <p className="description" key={items.controlId}>
                        {items.value}
                    </p>
                );
                break;
            case "button":
                itemsToRender.push(
                    <div
                        className="pb-3 d-flex"
                        key={items.controlId}
                    >
                        <button
                            className="btn btn-secondary btn-info-close min-width6 close"
                            key={items.controlId}
                            onClick={() => {
                                props.onSubmit(items.onClick, null);
                            }}
                        >
                            {items.label}
                        </button>
                    </div>
                );
                break;
            case "selectpicker":
                itemsToRender.push(
                    <div key={items.controlId} className="wrapper">
                        <h6 className="involve-case-title">{items.label}</h6>
                        <div className="involve-case-container">
                            <select
                                className="involve-case"
                                required
                                name={items.controlId}
                                key={items.controlId}
                                onChange={(e) => {
                                    const id = items.picklist.values.find(
                                        (options: any, index: any) => {
                                            return e.target.value === options.id;
                                        }
                                    );

                                    if (involveCase === null) {
                                        setInvolveCase({
                                            [items.controlId]: id
                                        });
                                    } else {
                                        // update involved case to state if control id is set
                                        setInvolveCase({
                                            ...involveCase,
                                            [items.controlId]: id
                                        });
                                    }
                                }}
                            >
                                <option value={""}>Select</option>
                                {items.picklist.values.map((options: any, index: any) => {
                                    return (
                                        <option value={options.id} key={options.id}>
                                            {options.value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                );
                break;
            case "submit":
                let isSelected =
                    involveCase === "" && involveCase === null ? true : false;
                itemsToRender.push(
                    isFetching ? (
                        <button
                            type="button"
                            disabled={true}
                            className={"btn btn-primary min-width6 btn-disabled"}
                            key={items.controlId + "_disabled"}
                        >
                            Requesting
                        </button>
                    ) : (
                        <button
                            type="submit"
                            disabled={isSelected ? true : undefined}
                            className={
                                "btn btn-primary min-width6 " +
                                (isSelected ? "btn-disabled" : "")
                            }
                            key={items.controlId}
                        >
                            {items.label}
                        </button>
                    )
                );
                break;
            default:
                return null;
        }

        return itemsToRender;
    };

    return (
        <div className="information-governance">
            <div
                className="modal fade show"
                id="exampleModal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {props.data.title}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form
                                className="information-governance-container"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    callApiAndRedirect(e);
                                }}
                            >
                                <div className="content-container">
                                    {props.data.controls.map((items: any, index: any) => {
                                        return __renderLayout(items, index);
                                    })}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InformationGovernance;
