// Importing React hooks and components
import { useEffect, useState } from 'react';
import { DataGrid, Summary } from './';
import { useSelector } from 'react-redux';
import KendoGrid from '../../../components/containers/kendo-grid';
import { use } from 'i18next';

/**
 * Tabs component to display tabular content based on caseData.
 *
 * @param {any} props - Data received from a parent component.
 * @returns {JSX.Element} - Rendered component.
 */
const Tabs: any = (props: any) => {

    // usePrintble hook to print the component
    const uiStates: any = useSelector<any>(state => state.uiStates);

    // State for active tab and case data
    const [activeTab, setActiveTab] = useState<any>(null)
    const [caseData, setCaseData] = useState<any>(null)

    // fetch case data for active tab on mount
    useEffect(() => {
        if (props.activeTab !== null) {
            props.onClickTab(props.activeTab)
        }
    }, [])

    useEffect(() => {
        try {
            console.log(props.caseData)
            if (props.caseData[props.activeTab]) {
                setCaseData(props.caseData[props.activeTab])
            }
        } catch (error) {
            console.log(error)
        }
    }, [props.caseData])

    useEffect(() => {
        try {
            console.log(props.activeTab)
            if (props.activeTab) {
                setActiveTab(props.activeTab)
            }
        } catch (error) {
            console.log(error)
        }
    }, [props.activeTab])



    // useEffect to update case data when props change
    useEffect(() => {
        if (props.caseData !== null && props.activeTab !== null && props.caseData.hasOwnProperty(props.activeTab)) {
            if (props.caseData[props.activeTab === activeTab ? props.activeTab : activeTab] !== undefined) {
                setCaseData(props.caseData[props.activeTab === activeTab ? props.activeTab : activeTab])
            }
        }
    }, [props]);

    /**
     * Event handler for tab click. Sets active tab and fetches corresponding case data.
     *
     * @param {string} id - ID of the clicked tab.
     */
    const _handleTabClick = (id: string): void => {
        setActiveTab(id);
        setCaseData(null)


        if (props.caseData[id] === undefined) {
            props.onClickTab(id)
        } else {
            setCaseData(props.caseData[id])
        }
    }

    /**
    * Helper function to render the tabs header.
    *
    * @param {any} data - Data to populate the tabs.
    * @returns {JSX.Element} - Rendered JSX for the tab headers.
    */
    const __renderTabHeader = (data: any) => {
        return <div className='tabs-container'>
            <ul className="nav nav-tabs">
                {data.map((res: any, index: number) => {

                    let customStyle = {};
                    if (res.tableHeaderColour !== "") {
                        if (activeTab === res.id) {
                            customStyle = res.tableHeaderColour !== "" ? { backgroundColor: res.tableHeaderColour, color: res.tableHeaderFontColour } : {}
                        } else {
                            customStyle = res.tableHeaderColour !== "" ? { color: res.tableHeaderColour, backgroundColor: res.tableHeaderFontColour } : {}
                        }
                    }

                    return <li className={activeTab === res.id ? "nav-item active" : "nav-item"} key={res.id}>
                        <button onClick={() => _handleTabClick(res.id)} className={"nav-link " + activeTab === res.id ? "active" : ""} aria-current="page" style={customStyle} >{res.title}</button>
                    </li>
                })}</ul>
        </div>
    }



    const __renderTabContainer = (data: any) => {
        return <div className='tabs-container'>
            {data.map((res: any, index: number) => {
                const caseData = props.caseData[res.id]
                const type = props.tabs[index].type;

                let renderHTMLData = null;
                if (type === "summary") {
                    renderHTMLData = (caseData !== null ? <Summary data={caseData} /> : null)
                } else if (type === "datagrid") {
                    renderHTMLData = (<>
                        {(caseData !== null && res?.summaryTextLocation !== "bottom" ? <Summary data={caseData} /> : null)}
                        {caseData && caseData?.caseSummaryDataColumns ?
                            uiStates.isPrintable ?
                                <DataGrid key={props.key}
                                    pageSize={props.tabs[index].pageSize}
                                    caseData={caseData}
                                /> :
                                <KendoGrid key={props.key}
                                    pageSize={props.tabs[index].pageSize}
                                    caseData={caseData}
                                />
                            : caseData?.emptyMessage !== "" ? caseData?.emptyMessage : <div>
                                Loading
                            </div>}
                        {(caseData !== null && res?.summaryTextLocation === "bottom" ? <Summary data={caseData} /> : null)}
                    </>)
                }

                return (
                    <div style={res.id === activeTab ? { display: "block" } : { display: "none" }} key={res.id}>
                        {uiStates.isPrintable === true ? <h4 className="tab-title">{props.tabs[index].title}</h4> : null}
                        {renderHTMLData}
                    </div>
                )

            })}
        </div>
    }
    // Main component render
    return (
        <div className='pb-4'>
            {(uiStates.isPrintable !== true && caseData !== null) ? __renderTabHeader(props.tabs) : null}

            <div className="tab-content">
                {caseData !== null ? __renderTabContainer(props.tabs) : null}
            </div>
        </div>
    )
}

// Export the Tabs component
export default Tabs;