// Import required modules and configuration
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';

// Import the action types from the constants file
import * as types from "./types";
import { getNewAxiosInstance } from "../../../utils";
import { error } from 'console';


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchCaseSummaryData(res: any): SagaIterator {
    const dataId = res.payload.dataId

    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        let apiEndPoint = `/casesummarydata/${res.payload.dataId}/${res.payload.caseId}`;
        if (res.payload.fetchSelected) {
            apiEndPoint = `/casesummarydata/${res.payload.caseId}?dataIds=${res.payload.dataId}`;
        }

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, apiEndPoint);

        // Dispatch action to update state with received data
        yield put({
            type: types.CASE_SUMMARY_DATA_CM_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.CASE_SUMMARY_DATA_CM_FAILURE, payload: { dataId: dataId, error: e } });
    }
}


/**
 * Fetches overview reference from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchOverviewReference(res: any): SagaIterator {

    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch overview reference
        const response: AxiosResponse = yield call(ins.get, `${res.payload.url}?oneViewReference=${res.payload.caseReference}&dspId=${res.payload.dspId}&dspRoleId=${res.payload.dspRoleId}`);

        // Dispatch action to update state with received data
        yield put({ type: types.FETCH_OVERVIEWREFERENCE_CM_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FETCH_OVERVIEWREFERENCE_CM_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches overview reference access from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchOverviewReferenceAccess(res: any): SagaIterator {

    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch overview reference
        const response: AxiosResponse = yield call(ins.get, `/oneviewreference/oneViewAccess?dspId=${res.payload.dspId}&dspId=${res.payload.dspRoleId}&oneViewReference=${res.payload.oneViewReference}`);

        // Dispatch action to update state with received data
        yield put({ type: types.FETCH_OVERVIEWREFERENCE_ACCESS_CM_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FETCH_OVERVIEWREFERENCE_ACCESS_CM_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches case summary config from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchCaseSummaryConfigAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.get, `/casesummaryconfig/config/${res.payload.caseSummaryType}?source=CM`);

        // Dispatch action to update state with received data
        yield put({ type: types.CASE_SUMMARY_CONFIG_CM_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.CASE_SUMMARY_CONFIG_CM_FAILURE, payload: { error: e.response } });
    }
}

/**
 * Fetches information governance data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchInformationGovernanceAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch information governance data
        const response: AxiosResponse = yield call(ins.get, `/ig/cm-config/${res.payload.dspId}`, { params: res.payload.params });

        // Dispatch action to update state with received data
        yield put({ type: types.INFORMATION_GOVERNANCE_DATA_CM_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.INFORMATION_GOVERNANCE_DATA_CM_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches IGAPI data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */

function* fetchIGApi(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch information governance data
        const apiCall = () => {
            return ins.post(`${res.payload.url}?dspId=${res.payload.body}`, null, { headers: { accept: 'application/json', 'caseSummaryType': res.payload.caseSummaryType } })
        }

        const response: AxiosResponse = yield call(apiCall);

        // Dispatch action to update state with received data
        yield put({ type: types.IGAPI_DATA_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.IGAPI_DATA_FAILURE, payload: { error: e.response } });
    }
}



/**
 * Fetches case detail all data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchCaseDetailAll(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.post, `/case-details/all?dspId=${res.payload.param.dspId}&controlId=${res.payload.param.controlId}&oneViewReference=${res.payload.param.oneViewReference}`);
        // Dispatch action to update state with received data
        yield put({
            type: types.CASE_DETAIL_ALL_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.CASE_DETAIL_ALL_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case detail add outcome data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchAddOutcomeDetail(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.post, `/case-details/add?dspId=${res.payload.param.dspId}&controlId=${res.payload.param.controlId}&oneViewReference=${res.payload.param.oneViewReference}`,res.payload.body);
        // Dispatch action to update state with received data
        yield put({
            type: types.ADD_OUTCOME_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.ADD_OUTCOME_FAILURE, payload: { error: e } });
    }
}



/**
 * Fetches case detail Add edit popup data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchAddEditPopupDetail(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `/case-management/add-edit-config/${res.payload.param.dspId}/${res.payload.param.controlId}`);
        // Dispatch action to update state with received data
        yield put({
            type: types.ADD_EDIT_POPUP_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.ADD_EDIT_POPUP_FAILURE, payload: { error: e } });
    }
}       


/**
 * Fetches case detail Add edit popup data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchPickListData(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `${res.payload.url}?controlId=${res.payload.param.controlId}&dspId=${res.payload.param.dspId}`);
        // Dispatch action to update state with received data
        yield put({
            type: types.PICK_LIST_DATA_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.PICK_LIST_DATA_FAILURE, payload: { error: e } });
    }
}    


// * Fetches case detail Add edit popup data from the API and updates the state.
//  *
//  * @param {any} res - The response object containing dataId and token.
//  *
function* fetchDeleteData(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.delete, `/case-details/delete?controlId=${res.payload.param.controlId}&dspId=${res.payload.param.dspId}&id=${res.payload.param.id}`);
        // Dispatch action to update state with received data
        yield put({
            type: types.DELETE_OUTCOME_DATA_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.DELETE_OUTCOME_DATA_FAILURE, payload: { error: e } });
    }
}   


// * Fetches case detail get edit popup data from the API and updates the state.
//  *
//  * @param {any} res - The response object containing dataId and token.
//  *
function* fetchGetEditData(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);   
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `/case-details/get?controlId=${res.payload.param.controlId}&dspId=${res.payload.param.dspId}&id=${res.payload.param.id}`);
        // Dispatch action to update state with received data
        yield put({
            type: types.GET_EDIT_OUTCOME_DATA_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.GET_EDIT_OUTCOME_DATA_FAILURE, payload: { error: e } });
    }
}    


// * Fetches case detail get edit popup data from the API and updates the state.
//  *
//  * @param {any} res - The response object containing dataId and token.
//  *
function* fetchEditData(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);   
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.put, `/case-details/edit?controlId=${res.payload.param.controlId}&dspId=${res.payload.param.dspId}&id=${res.payload.param.id}&oneViewReference=${res.payload.param.oneViewReference}`,res.payload.body);
        // Dispatch action to update state with received data
        yield put({
            type: types.EDIT_OUTCOME_DATA_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.EDIT_OUTCOME_DATA_FAILURE, payload: { error: e } });
    }
}    


/**
 * Fetches Feedback-cs-columns data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSColumnAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.get, `/cs-feedback/columns?caseSummaryType=${res.payload.params.caseSummaryType}`);
        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_COLUMNS_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_COLUMNS_FAILURE, payload: { error: e.response } });
    }
}

/**
 * Fetches Feedback-cs data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.get, `/cs-feedback?caseSummaryType=${res.payload.params.caseSummaryType}&oneViewReference=${res.payload.params.oneViewReference}&dspId=${res.payload.params.dspId}`);
        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_FAILURE, payload: { error: e.response } });
    }
}

/**
 * Fetches Feedback-cs-add data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSAddAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);



        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.post, `/cs-feedback/add`, res.payload.params);

        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_ADD_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_ADD_FAILURE, payload: { error: e.response } });
    }
}

/**
 * Fetches Feedback-cs-edit data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSEditAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.put, `/cs-feedback/edit`, res.payload.params);

        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_EDIT_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_EDIT_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches Feedback-cs-delete data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSDeleteAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.delete, `/cs-feedback/delete?oneViewReference=${res.payload.params.oneViewReference}&feedbackId=${res.payload.params.feedbackId}`);
        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_DELETE_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_DELETE_FAILURE, payload: { error: e.response } });
    }
}

/**
 * Fetches fetch all engagment tracker data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchAllEngagmentTrackerAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.post, `/case-details/all?dspId=${res.payload.param.dspId}&controlId=${res.payload.param.controlId}&oneViewReference=${res.payload.param.oneViewReference}`,res.payload.body);
        // Dispatch action to update state with received data
        yield put({ type: types.FETCH_ALL_ENGAGMENT_TRACKER_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FETCH_ALL_ENGAGMENT_TRACKER_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches case detail Add edit popup data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchAddEditEngagmentPopupDetail(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `/case-management/add-edit-config/${res.payload.param.dspId}/${res.payload.param.controlId}`);
        // Dispatch action to update state with received data
        yield put({
            type: types.ADD_EDIT_ENGAGMENT_POPUP_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.ADD_EDIT_ENGAGMENT_POPUP_FAILURE, payload: { error: e } });
    }
}   



/**
 * Fetches case detail add outcome data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchAddEngagmentDetail(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.post, `/case-details/add?dspId=${res.payload.param.dspId}&controlId=${res.payload.param.controlId}&oneViewReference=${res.payload.param.oneViewReference}`,res.payload.body);
        // Dispatch action to update state with received data
        yield put({
            type: types.ADD_ENGAGMENT_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        console.log("error",e)
        // Handle failure and update state
        yield put({ type: types.ADD_ENGAGMENT_FAILURE, payload: { error: e } });
    }
}

// * Fetches case detail get edit popup data from the API and updates the state.
//  *
//  * @param {any} res - The response object containing dataId and token.
//  *
function* fetchGetEditEngagmentData(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);   
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `/case-details/get?controlId=${res.payload.param.controlId}&dspId=${res.payload.param.dspId}&id=${res.payload.param.id}`);
        // Dispatch action to update state with received data

        const data = response.data.values.map((item: any, index: number) => {
            return {textToDisplay: item.textToDisplay, valueToStore:item.valueToStore }
        });

        response.data.values = data;
        response.data.controlId = res.payload.param.controlId;


        yield put({
            type: types.GET_EDIT_ENGAGMENT_DATA_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.GET_EDIT_ENGAGMENT_DATA_FAILURE, payload: { error: e } });
    }
} 


// * Fetches case detail get edit popup data from the API and updates the state.
//  *
//  * @param {any} res - The response object containing dataId and token.
//  *
function* fetchEngagmentEditData(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);   
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.put, `/case-details/edit?controlId=${res.payload.param.controlId}&dspId=${res.payload.param.dspId}&id=${res.payload.param.id}&oneViewReference=${res.payload.param.oneViewReference}`,res.payload.body);
        // Dispatch action to update state with received data
        yield put({
            type: types.EDIT_ENGAGMENT_DATA_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.EDIT_ENGAGMENT_DATA_FAILURE, payload: { error: e } });
    }
}    


// * Fetches case detail Add edit popup data from the API and updates the state.
//  *
//  * @param {any} res - The response object containing dataId and token.
//  *
function* fetchDeleteEngagmentData(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.delete, `/case-details/delete?controlId=${res.payload.param.controlId}&dspId=${res.payload.param.dspId}&id=${res.payload.param.id}`);
        // Dispatch action to update state with received data
        yield put({
            type: types.DELETE_ENGAGMENT_DATA_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.DELETE_ENGAGMENT_DATA_FAILURE, payload: { error: e } });
    }
}   


/**
 * Fetches case detail Add edit popup data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchPickListEngagmentData(res: any): SagaIterator {
    
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `${res.payload.url}?controlId=${res.payload.param.controlId}&dspId=${res.payload.param.dspId}&parentPickListSelectedValue=${res.payload.param.parentPickListSelectedValue}`);
        // Dispatch action to update state with received data
        
        const data = response.data.values.map((item: any, index: number) => {
            return {textToDisplay: item.textToDisplay, valueToStore:item.valueToStore }
        });

        response.data.values = data;
        response.data.controlId = res.payload.param.controlId;

        yield put({
            type: types.PICK_LIST_ENGAGMENT_DATA_RECEIVE,
            payload: response.data
        }); 
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.PICK_LIST_ENGAGMENT_DATA_FAILURE, payload: { error: e } });
    }
}    




/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchHouseHoldComposition(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        let apiEndPoint = `/hhc/alert-message`;

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, apiEndPoint);

        // Dispatch action to update state with received data
        yield put({
            type: types.HHC_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.HHC_FAILURE, payload: { error: e } });
    }
}




/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchAssets(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        let apiEndPoint = `/case-details/document-link?docFilePath=${res.payload.url}`;

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, apiEndPoint);

        // Dispatch action to update state with received data
        yield put({
            type: types.DOWNLOAD_ASSETS_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.DOWNLOAD_ASSETS_FAILURE, payload: { error: e } });
    }
}
/**
 * The main saga watcher. Will fork all other sagas into the middleware.
 */
export default function* fetchSummaryOneViewSaga() {
    yield takeEvery(types.FEEDBACK_CS_DELETE_REQUEST, fetchFeedbackCSDeleteAPI)
    yield takeEvery(types.FEEDBACK_CS_EDIT_REQUEST, fetchFeedbackCSEditAPI)
    yield takeEvery(types.FEEDBACK_CS_COLUMNS_REQUEST, fetchFeedbackCSColumnAPI)
    yield takeLatest(types.FEEDBACK_CS_ADD_REQUEST, fetchFeedbackCSAddAPI)
    yield takeLatest(types.FEEDBACK_CS_REQUEST, fetchFeedbackCSAPI)
    yield takeLatest(types.CASE_SUMMARY_CONFIG_CM_REQUEST, fetchCaseSummaryConfigAPI);
    yield takeLatest(types.INFORMATION_GOVERNANCE_DATA_CM_REQUEST, fetchInformationGovernanceAPI);
    yield takeLatest(types.FETCH_OVERVIEWREFERENCE_CM_REQUEST, fetchOverviewReference);
    yield takeLatest(types.FETCH_OVERVIEWREFERENCE_ACCESS_CM_REQUEST, fetchOverviewReferenceAccess);
    yield takeLatest(types.IGAPI_DATA_REQUEST, fetchIGApi);
    yield takeEvery(types.CASE_SUMMARY_DATA_CM_REQUEST, fetchCaseSummaryData);
    yield takeLatest(types.CASE_DETAIL_ALL_REQUEST, fetchCaseDetailAll);
    yield takeLatest(types.ADD_OUTCOME_REQUEST, fetchAddOutcomeDetail);
    yield takeLatest(types.ADD_EDIT_POPUP_REQUEST, fetchAddEditPopupDetail);
    yield takeLatest(types.PICK_LIST_DATA_REQUEST, fetchPickListData);
    yield takeLatest(types.DELETE_OUTCOME_DATA_REQUEST, fetchDeleteData);
    yield takeLatest(types.GET_EDIT_OUTCOME_DATA_REQUEST, fetchGetEditData);
    yield takeLatest(types.EDIT_OUTCOME_DATA_REQUEST, fetchEditData);
    yield takeLatest(types.FETCH_ALL_ENGAGMENT_TRACKER_REQUEST, fetchAllEngagmentTrackerAPI);
    yield takeLatest(types.ADD_EDIT_ENGAGMENT_POPUP_REQUEST, fetchAddEditEngagmentPopupDetail);
    yield takeLatest(types.ADD_ENGAGMENT_REQUEST, fetchAddEngagmentDetail);
    yield takeLatest(types.EDIT_ENGAGMENT_DATA_REQUEST, fetchEngagmentEditData);
    yield takeEvery(types.GET_EDIT_ENGAGMENT_DATA_REQUEST, fetchGetEditEngagmentData);
    yield takeLatest(types.DELETE_ENGAGMENT_DATA_REQUEST, fetchDeleteEngagmentData);
    yield takeEvery(types.PICK_LIST_ENGAGMENT_DATA_REQUEST, fetchPickListEngagmentData);
    yield takeLatest(types.HHC_REQUEST, fetchHouseHoldComposition);
    yield takeLatest(types.DOWNLOAD_ASSETS_REQUEST, fetchAssets);
}