import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { pickListEngagmentDataPopup, resetPickEngagmentListData } from "../store/actions";
import { useEffect, useState } from "react";

const ChildPicklistIControlidsPicker = (props: any) => {
    const dispatch = useDispatch();
    const pickList = useSelector((state: any) => state.selectEngagmentPickCM);
    const [data, setData] = useState([])

    useEffect(() => {
        // Fetch Information pickList
        try {
            dispatch(
                pickListEngagmentDataPopup({
                    token: props.token,
                    param: {
                        dspId: props.dspId,
                        parentPickListSelectedValue: "",
                        controlId: props.items.controlId,
                    },
                    // url: props.items.apiPicklistEndpoint.endpointUrl.replace("/api", ""),
                    url: "dropdownOption",
                })
            );
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [pickListEngagmentDataPopup]);

    useEffect(() => {
        if (pickList.isSuccess && pickList.data !== null && props.items.controlId === pickList.data.controlId) {
            setData(pickList.data.values);
            dispatch(resetPickEngagmentListData())
        } else if (pickList.isError) {
            dispatch(resetPickEngagmentListData())
        }

    }, [pickList])

    return (
        <div className="column" key={props.items.controlId}>
            <h6 className="involve-case-title">{props.items.label}</h6>
            <div className={"involve-case-container"}>
                <select
                    disabled={!props.items.isEditable}
                    className="involve-case"
                    name={props.items.name}
                    key={props.items.controlId}
                    onChange={props.handleChange}
                    value={props.formData[props.items.name]}
                >
                    {data.map((option: any) => (
                        <option value={option.valueToStore} key={option.valueToStore}>
                            {option.textToDisplay == "" ? "select" : option.textToDisplay}
                        </option>
                    ))}
                </select>
            </div>
        </div>)
}
export default ChildPicklistIControlidsPicker;