// Importing React hooks and components
import { useEffect, useState } from 'react';
import DataGrid from './datagrid';
import Summary from './summary';

import { useSelector } from 'react-redux';

/**
 * Tabs component to display tabular content based on caseData.
 *
 * @param {any} props - Data received from a parent component.
 * @returns {JSX.Element} - Rendered component.
 */
const Tabs: any = (props: any) => {

    // usePrintble hook to print the component
    const uiStates: any = useSelector<any>(state => state.uiStates);

    // State for active tab and case data
    const [activeTab, setActiveTab] = useState<any>(null)
    const [caseData, setCaseData] = useState<any>(null)

    // fetch case data for active tab on mount
    useEffect(() => {
        if (props.activeTab !== null) {
            props.onClickTab(props.activeTab)
        }
    }, [])

    // useEffect(() => {
    //     try {
    //         console.log(props.caseData)
    //         if (props.caseData[props.activeTab]) {
    //             setCaseData(props.caseData[props.activeTab])
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }, [props.caseData])

    useEffect(() => {
        try {
            if (props.activeTab) {
                setActiveTab(props.activeTab)
            }
        } catch (error) {
            console.log(error)
        }
    }, [props.activeTab])


    /**
     * Event handler for tab click. Sets active tab and fetches corresponding case data.
     *
     * @param {string} id - ID of the clicked tab.
     */
    const _handleTabClick = (id: string): void => {
        setActiveTab(id);
        // setCaseData(null)


        // if (props.caseData[id] === undefined) {
        props.onClickTab(id)
        // } else {
        //     setCaseData(props.caseData[id])
        // }
    }

    /**
    * Helper function to render the tabs header.
    *
    * @param {any} data - Data to populate the tabs.
    * @returns {JSX.Element} - Rendered JSX for the tab headers.
    */
    const __renderTabHeader = (data: any) => {
        return <div className='tabs-header'>
            <ul className="nav nav-tabs">
                {data.map((res: any, index: number) => {


                    let customStyle = {};
                    if (res.tableHeaderColour !== "") {
                        if (activeTab === res.tabId) {
                            customStyle = res.tableHeaderColour !== "" ? { backgroundColor: res.tableHeaderColour, color: res.tableHeaderFontColour } : {}
                        } else {
                            customStyle = res.tableHeaderColour !== "" ? { color: res.tableHeaderColour, backgroundColor: res.tableHeaderFontColour } : {}
                        }
                    }

                    return <li className={activeTab === res.tabId ? "nav-item active" : "nav-item"} key={res.tabId}>
                        <button onClick={() => _handleTabClick(res.tabId)} className={"nav-link " + activeTab === res.tabId ? "active" : ""} aria-current="page" style={customStyle} >{res.title || "Case Summary"}</button>
                    </li>
                })}</ul>
        </div>
    }



    const __renderTabContainer = (data: any) => {
        return <div className='tabs-container'>
            {data.map((res: any, index: number) => {
                let renderHTMLData = null;
                if (res.tabId === activeTab && res.type === "casesummary") {
                    renderHTMLData = props.caseSummary
                } else if (res.type === "iframe") {
                    renderHTMLData = <iframe src={res.iframe.iframeBaseUrl} width="100%" height="100%" style={{ minHeight: 400 }} frameBorder="0"></iframe>
                } else if (res.tabId === activeTab && res.title == "Outcomes") {
                    renderHTMLData = props.Outcome
                } else if (res.tabId === activeTab && res.title == "Engagement tracker") {
                    renderHTMLData = props.EngagementTracker
                }
                else if (res.tabId === activeTab && res.title === "Case Notes") {
                    renderHTMLData = props.caseNotes
                }

                return (
                    <div style={res.tabId === activeTab ? { display: "block" } : { display: "none" }} key={res.tabId}>
                        {renderHTMLData}
                    </div>
                )
            })}
        </div>
    }


    // Main component render
    return (
        <div className='pb-4'>
            {__renderTabHeader(props.tabs)}

            <div className="tab-content">
                {__renderTabContainer(props.tabs)}
            </div>
        </div>
    )
}

// Export the Tabs component
export default Tabs;