import { xanturaLogo } from "../../../assets/icons";

import { DropDown, useLegacySupport, Button } from "../../../components";
import { useMsal } from "@azure/msal-react";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeaderDropdown from "../../case-summary/components/headerDropdown";

const TopHeader = (props: any) => {
  const legacy = useLegacySupport();
  const { t } = useTranslation();

  const { instance } = useMsal();

  const dspCodes: any = useSelector<any>((state) => state.dspCodes);

  const [dspData, setDspData] = useState<any>([]);
  const [dspSelectedItem, setDspSelectedItem] = useState<any>(null);

  useEffect(() => {
    if (dspCodes.isSuccess && dspCodes.data !== null) {
      const data = dspCodes.data;
      if (data.defaultSelectedValue) {
        setDspSelectedItem(data.defaultSelectedValue);
      }

      const dspData = data.values.map((item: any, index: number) => {
        return {
          id: index,
          value: item.valueToStore,
          title: item.textToDisplay,
        };
      });
      setDspData(dspData);
    }
  }, [dspCodes]);

  const __logout = () => {
    try {
      if (legacy.isLegacySupported === false) {
        console.log("azure logout");
        window.localStorage.clear();

        const homeAccountId: any = instance.getActiveAccount()?.homeAccountId;
        const logoutRequest = {
          account: instance.getAccountByHomeId(homeAccountId),
          mainWindowRedirectUri: "/",
          postLogoutRedirectUri: "/",
        };
        instance.logoutPopup(logoutRequest);
      } else {
        console.log("legacy logout");
        window.localStorage.clear();
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <header className="top-header">
      <h1>
        <a href="/" title="Homepage">
          <img src={xanturaLogo} alt="Xantura logo" className="logo" />
        </a>
      </h1>
      <div className="button-wrapper">
        <div className="filter-fields">
          <div className={"select-container"}>
            <div className="select-button-add">
              <button onClick={props.onCreateCaseClick} type="button">{t("CREACT_CASE")}</button>
            </div>
          </div>
        </div>

        <DropDown
          label="SELECT DATA PROTOCOL"
          labelExternal={false}
          name={"selectCase"}
          data={dspData}
          defaultSelected={dspSelectedItem}
          isMultiSelectPickList={false}
          onChange={(e) => {
            setDspSelectedItem(e.value);
            props.onChange({ type: "dsp", value: e.value, title: e.title });
          }}
        />
        {/* <Button className="btn-cta blue-background" title={t("LOG_OUT")} onClick={() => __logout()} /> */}
        <HeaderDropdown label={props.username} labelExternal={false} />
      </div>
    </header>
  );
};

export default TopHeader;
