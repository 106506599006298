import React, { useState } from 'react';
import { contextmenu, details_icon } from '../../../assets/icons';
import { t } from "i18next";

const CustomAction = (props: any, res: any) => {

    const selectedRecord = props.data;

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [toggleDropDown, setToggleDropDown] = useState<boolean>(false);
    const [toggleDropDownXY, setToggleDropDownXY] = useState<any>({
        x: 0, y: 0
    });


    const detailedGrid = () => {
        props.onClick({ data: selectedRecord, action: "details" })
    };

    const editGrid = () => {
        console.log("edit", props)
        props.onClick({ data: selectedRecord, action: "edit" })
    }

    const removeGrid = () => {
        props.onClick({ data: selectedRecord, action: "delete" })
    }


    const __renderDropdown = () => {
        return toggleDropDown ? <>
            <div className="" style={{ position: "fixed", top: (toggleDropDownXY.y + 10), left: (toggleDropDownXY.x - 50), zIndex: 999 }}>
                <ul className={`dropdown-menu ${toggleDropDown ? "show" : "hide"}`}>
                    {props.isEditButton ? <li><button className="btn btn-sm btn-block" onClick={() => {
                        setToggleDropDown(false)
                        editGrid()
                    }}>Edit</button></li> : null}
                    <li><button className="btn btn-sm btn-block" style={{ color: confirmDelete ? '#ffffff' : '#5464B0', backgroundColor: confirmDelete ? '#FF0000' : '#ffffff' }} onClick={() => {
                        if (confirmDelete) {
                            removeGrid();
                            setToggleDropDown(false);
                        } else {
                            // Change the button text to "Confirm"
                            setConfirmDelete(true);
                        }
                    }}>{confirmDelete ? t("CONFIRM") : t("DELETE")}</button></li>
                    {/* <li><button className="btn btn-sm btn-block" onClick={() => {
                    setToggleDropDown(false)
                    removeGrid(selectedRecord)
                }}>Clear</button></li> */}
                </ul>
            </div>
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(255,255,255,0)", zIndex: 889 }}
                onClick={(e: any) => {
                    setToggleDropDown(false)
                }}></div>
        </> : null
    }


    return (<>
        {__renderDropdown()}
        <button className="btn btn-sm"
            onClick={(e) => {
                setConfirmDelete(false);
                setToggleDropDownXY({ x: e.clientX, y: e.clientY })
                setToggleDropDown(!toggleDropDown)
            }}
        ><img src={contextmenu} style={{ width: 20 }} /></button>

        {props.isDetails ? <button className="btn btn-sm" onClick={() => detailedGrid()}><img src={details_icon} style={{ width: 20 }} /></button> : null}
    </>);
};

export default CustomAction;
