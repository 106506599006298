import * as type from './types';
/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const communityAssetsConfig = (params: any) => {
    return { type: type.COMMUNITY_ASSETS_CONFIG_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetCommunityAssetsConfig = () => {
    return { type: type.RESET_COMMUNITY_ASSETS_CONFIG, payload: null }
}

/**
 * Dispatches an action to Google Map.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchMapCoOrdinates = (params: any) => {
    return { type: type.COMMUNITY_ASSETS_REQUEST, payload: params }
}


/**
 * Resets the state of Google Map.
 *
 * @returns {object} - The action object.
 */
export const resetFetchMapCoOrdinates = () => {
    return { type: type.RESET_COMMUNITY_ASSETS, payload: null }
}


/**
 * Dispatches an action to Google Map.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchCommunityAssetsList = (params: any) => {
    return { type: type.COMMUNITY_ASSETS_LIST_REQUEST, payload: params }
}


/**
 * Resets the state of Google Map.
 *
 * @returns {object} - The action object.
 */
export const resetFetchCommunityAssetsList = () => {
    return { type: type.RESET_COMMUNITY_ASSETS_LIST, payload: null }
}

