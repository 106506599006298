import { closeIcon } from "../../../assets/icons";
import SelectPicker from "./selectPicker";
import moment from "moment";
import { infinityLoader } from "../../../config/Images";
import { t } from "i18next";

// Functional component for Create case
const CreateCasePopup = (props: any) => {
  const formObj: any = {};
  console.log("props?.data", props?.data);

  // Function to render form iframe
  const _renderFormIframe = () => {
    const iframeUrl = props?.data;
    return (
      <div style={{ width: "100%", height: "80vh" }}>
        <iframe
          src={iframeUrl}
          style={{ width: "100%", height: "100%", border: "none" }}
          title="Form Iframe"
        />
      </div>
    );
  };

  return (
    <div className="addoutcome-popup">
      <div
        className="modal fade show"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("CREATECASESUMMARY")}
              </h5>
              <button
                className="btn btn-transparent close"
                onClick={() => {
                  props.onSubmit("", null);
                }}
              >
                <img src={closeIcon} alt="close" />
              </button>
            </div>
            <div className="modal-body">
              <form
                className="information-governance-container"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="content-container">{_renderFormIframe()}</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCasePopup;
