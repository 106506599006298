// Import React hooks and Kendo Grid components
import {
    Grid, GridColumn, GridEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridSelectionChangeEvent,
    GridHeaderSelectionChangeEvent,
    getSelectedState,
    GridExpandChangeEvent,
    GridCellProps,
    GridCustomCellProps
} from "@progress/kendo-react-grid"
import { useEffect, useState, useCallback, useRef } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import { SortDescriptor, filterBy, getter, orderBy } from "@progress/kendo-data-query";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";

import { infinityLoader } from "../../config/Images";
import { toast } from "react-toastify";
import { Input } from "@progress/kendo-react-inputs";

// Interface to type-check sort states
const initialSort: Array<SortDescriptor> = [
    { field: "id", dir: "asc" },
];

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "SELECTED_FIELD";
const EDITFIELD: string = "inEdit";

const idGetter = getter(DATA_ITEM_KEY);


const initialDataState = {
    skip: 0,
    take: 10,
};


export interface kendoGridProps {
    gridData: any;

    isExport: boolean;

    isSelectable: any;
    selectedField: string;
    isFilterable: boolean;
    filtersList: any;

    isPaginated: boolean;

    expandField: string;
    onExpand: (e: any) => void;
    onHiddenCount: (e: number) => void;

    // custom action layout for edit, details etc
    isCustomAction: boolean;
    customeActionLayout: (e: any) => any;
    onSelectionChange?: (e: any) => void;
    customCell?: (e: any) => any; 
}


/**
 * KendoGrid component to handle displaying grid data.
 *
 * @param {any} props - The props passed from the parent component.
 * @returns {JSX.Element} - The rendered component.
 */
const KendoGrid: any = (props: kendoGridProps) => {

    // set minVisibleColumnCount based on window width
    const width = window.innerWidth * 0.2;
    const windowWidth = (window.innerWidth - (width > 360 ? width : 360)) / 130;
    const minVisibleColumnCount = parseInt(windowWidth.toFixed(0));

    const isSelectable = props.isSelectable || "";
    const selectedField = props.selectedField || "";
    const customCell:any = props.customCell || {};

    // const _grid = useRef<any>(null);
    const _export = useRef<any>();

    // State management
    const [gridData, setGridData] = useState<any>(null);
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [isPaginated, setIsPaginated] = useState<any>(props.isPaginated);

    const [isHiddenColumn, setIsHiddenColumn] = useState<boolean>(false);
    const [hidenColumnCount, setHidenColumnCount] = useState<number>(0);
    const [selectedState, setSelectedState] = useState<{ [id: string]: boolean | number[]; }>({});

    const [filter, setFilter] = useState<any>();

    const [dataState, setDataState] = useState<any>(initialDataState);

    const [sort, setSort] = useState(initialSort);

    const [pageSize, setPageSize] = useState<number>(10);

    // Event handler for pagination change
    const pageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent;
        const take = event.page.take;

        if (targetEvent.value) {
            setPageSize(targetEvent.value);
        }

        setDataState({
            ...event.page,
            take,
        });

        // removed server side paging 
        // props.onPageChange(event);
    };


    useEffect(() => {
        document.addEventListener("asideToggle", (e) => __triggerAsideToggle());
        document.addEventListener("exportData", (e) => exportExcel());
        document.addEventListener("expandColumns", (e) => setIsHiddenColumn(isHiddenColumn => !isHiddenColumn));

        // return document.removeEventListener("asideToggle", (e) => __triggerAsideToggle);
    }, [])


    // Data processing useEffect
    useEffect(() => {
        if (props.gridData !== null) {

            if (props?.gridData?.gridColumns?.length > minVisibleColumnCount) {
                const hiddenColumnsCount = props?.gridData?.gridColumns?.length - minVisibleColumnCount;
                setIsHiddenColumn(true)
                setHidenColumnCount(hiddenColumnsCount)
                props.onHiddenCount(hiddenColumnsCount)
            }

            setGridData(props.gridData);

            if (isPaginated !== props.isPaginated) {
                setIsPaginated(props.isPaginated);
            }
        }
    }, [props]);

    const __triggerAsideToggle = () => {
        setIsHiddenColumn(false)
    }

    const _customActions = (res: GridCellProps) => {
        if (props.isCustomAction !== null && props.isCustomAction === false) {
            return null;
        }

        return (<td>
            <div>{props.customeActionLayout(res.dataItem)}</div>
        </td>)
    }

    const __inputFilters = (e: any) => {
        const value = e.target.value;
        const newFilter: any = {
            logic: "or",
            filters: []
        };

        props.gridData.gridColumns?.map((item: any) => {
            newFilter.filters.push({
                field: item.name.trim(),
                operator: "contains",
                value: value
            })
        });

        setGridData({
            ...gridData,
            gridRows: filterBy(props.gridData.gridRows, newFilter)
        })
    }




    const __renderGridToolbar = () => {
        if (!hidenColumnCount) {
            return null
        }

        return <GridToolbar>
            {
                props.filtersList ?
                    <div style={{ flexGrow: 1 }}>
                        <Input
                            placeholder="Search..."
                            onChange={(e) => __inputFilters(e)} />
                    </div> : null
            }
            {/* <div className="export-btns-container">
                <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => exportExcel()}>Export to Excel</button>
            </div> */}

            {/* <div className="pr-1 d-inline-block"></div>
            <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => { }}>Select</button> */}
            {/* <div className="pr-1 d-inline-block"></div> */}

            <div>
                <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => {
                    setIsHiddenColumn(!isHiddenColumn);
                }}>{isHiddenColumn ? "+" : "-"} {hidenColumnCount}</button>
            </div>
        </GridToolbar>
    }

    const __onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {

            const newSelectedState: any = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);


            if (props.onSelectionChange) {
                props.onSelectionChange(event.dataItem);
            }

        },
        [selectedState]
    );

    const __onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState: any = {};

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
        },
        []
    );

    const exportExcel = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const __renderColumns = () => {

        const columns: any = [];
        if (props.expandField && props.expandField !== "") {
            columns.push(<GridColumn field={selectedField} filterable={false} width={40} />)
        }

        if (props.isCustomAction && props.isCustomAction === true) {
            columns.push(<GridColumn cell={_customActions
                // () => <CustomAction
                // onClickMenu={props.isCustomAction}/>
            } filterable={false}
                title="Actions" width={100} />)
        }

        props.gridData.gridColumns.map((tdCol: any, index: number) => {
            if (tdCol.isVisible) {
                let grid = null;
                if (typeof customCell === "function" && customCell()[tdCol.name.trim()]) {
                    grid = <GridColumn
                        key={`column_name_${tdCol.name.trim()}`}
                        
                        title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                        width={tdCol.width === 0 ? "auto" : tdCol.width}

                        cell={customCell()[tdCol.name.trim()]}
                    // columnMenu={() => <GridColumnMenuCheckboxFilter {...props} field={tdCol.name.trim()}  data={gridData} expanded={true}/>}
                    // editable={["CaseOfficer", "CaseStatus"].indexOf(tdCol.name) > -1 ? true : false}
                    />
                }else { 
                    // optimization for hidden columns
                    grid = <GridColumn
                        key={`column_name_${tdCol.name.trim()}`}
                        field={tdCol.name.trim()}
                        title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                        width={tdCol.width === 0 ? "auto" : tdCol.width}
                        
                    // columnMenu={() => <GridColumnMenuCheckboxFilter {...props} field={tdCol.name.trim()}  data={gridData} expanded={true}/>}
                    // editable={["CaseOfficer", "CaseStatus"].indexOf(tdCol.name) > -1 ? true : false}
                    />
                }
                
                if (isHiddenColumn) {
                    if (index < minVisibleColumnCount) {
                        columns.push(grid)
                    }
                } else {
                    columns.push(grid)
                }
            }
        })
        return columns
    }


    const __expandChange = (event: GridExpandChangeEvent) => {
        if (event.dataItem !== "") {
            props.onExpand(event.dataItem)

            if (!expandedRows.includes(event.dataItem?.Group_Ref)) {
                setExpandedRows((expandedRows: any) => [...expandedRows, event.dataItem?.Group_Ref])
            }
        }
    }



    const __onDataStateChange = (e: any) => {
        setDataState(e.dataState);
    }


    const __renderShowDetailsColumns = (res: any) => {
        const columns: any = []

        res?.gridColumns?.map((tdCol: any, index: number) => {

            // optimization for hidden columns
            const grid = <GridColumn
                key={`column_name_${tdCol.name.trim()}`}
                field={tdCol.name.trim()}
                title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                width={tdCol.width === 0 ? "auto" : tdCol.width}
            // editable={["CaseOfficer", "CaseStatus"].indexOf(tdCol.name) > -1 ? true : false}
            />
            columns.push(grid)

        })

        return columns
    }



    const __showDetails = (props: GridCustomCellProps) => {
        if (props.dataItem === null || props.dataItem === undefined) {
            return null
        }
        return <div>
            <Grid
                key={"childdata"}

                // {...props}
                data={props?.dataItem?.details?.gridRows}
                resizable={true}
            >
                {__renderShowDetailsColumns(props.dataItem.details)}
            </Grid>
        </div>

    }

    const __onExportComplete = (e: any) => {
        toast.success("Data exported successfully")
    }

    const __getData = () => {

        let data = orderBy(filterBy(gridData?.gridRows.map((item: any) => ({
            ...item, [selectedField]: selectedState[idGetter(item)],
        })), filter), sort)

        let paginate = data?.slice(dataState.skip, (dataState.take + dataState.skip))

        return paginate
    }


    const __grid = () => {
        return (<Grid
            data={__getData()}

            columnVirtualization={false}

            style={{ maxHeight: "65vh", }}

            skip={dataState?.skip}
            take={dataState.take}
            total={gridData?.gridRows?.length}
            // pageSize={pageSize}
            pageable={isPaginated ? {
                buttonCount: 10,
                pageSizes: [10, 25, 50, 100],
                pageSizeValue: pageSize,
            } : false}
            onPageChange={pageChange}

            filterable={props.isFilterable || false}
            filter={filter}
            onFilterChange={(e) => setFilter(e.filter)}
            // onScroll={scrollHandler}
            // fixedScroll={true}

            selectable={isSelectable}

            dataItemKey={DATA_ITEM_KEY}
            selectedField={selectedField !== "" ? selectedField : ""}
            onSelectionChange={__onSelectionChange}
            onHeaderSelectionChange={__onHeaderSelectionChange}

            resizable={true}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}

            detail={(res: any) => __showDetails(res)}
            expandField={props.expandField}
            onExpandChange={(e) => __expandChange(e)}

        // scrollable={true}
        // {...dataState}
        // editField={EDITFIELD}
        >                        {/* Render columns */}
            {__renderColumns()}
        </Grid>)
    }

    const __renderGrid = () => {

        if (gridData?.gridRows?.length === 0) {
            return <div className="p-2">{props?.gridData?.emptyMessage}</div>
        }

        return __grid()

    }


    if (gridData === null) {
        return <div className="loading-data"><img src={infinityLoader} alt="Loading" /></div>;
    }

    // Main component render
    return (
        <div>
            {/* Render toolbar  */}
            {__renderGridToolbar()}

            {props.isExport ?
                <ExcelExport
                    data={(gridData?.gridRows?.filter((item: any) => {
                        return selectedState[idGetter(item)]
                    }))}
                    onExportComplete={(e) => __onExportComplete(e)}
                    fileName="case-data.xlsx"
                    ref={_export}
                /> : null}


            {__renderGrid()}

        </div>
    )
}

export default KendoGrid;