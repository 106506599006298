export default {
  translation: {
    REQUEST: "Requesting",
    SAVE: "SAVE",
    CANCEL: "CANCEL",
    NOTES: "Notes",
    ADDFEEDBACK: "Add feedback",
    UPDATE: "Update",
    DATA_SHARING_PROTOCALL: "Data Sharing Protocol",
    DOWNLOAD_PDF: "Download PDF",
    FILTER: "Filter",
    CASE_DETAILS_ID: "Overview Reference: ",
    CASE_DETAILS: "Overview Reference",
    CASES: "Cases ",
    LOG_OUT: "Log Out",
    CREACT_CASE: "Create Case",
    REQUESTING: "Requesting",
    ADDOUTCOME: "ADD OUTCOME  +",
    ADDOUTCOMEPOPUPHEAD: "ADD OUTCOME",
    EDITOUTCOMEPOPUPHEAD: "EDIT OUTCOME",
    CREATECASESUMMARY: "CREATE CASE",
    EDIT: "Edit",
    CONFIRM: "Confirm",
    DELETE: "Delete",
    ADDENGAGEMENT: "ADD ENGAGEMENT +",
    ADDENGAGEMENTPOPUPHEAD: "ADD ",
    EDITENGAGEMENTPOPUPHEAD: "EDIT ",
    ADDNOTES: "ADD NOTES  +",
    ADDNOTESPOPUPHEAD: "ADD NOTE",
  },
};
