import { parseDataByType } from "../../../utils";

const processDataForKendoGrid = (resData: any) => {
    try {
        let processedData: any = [];

        if (resData.gridRows.length > 0) {
            resData.gridRows.forEach((res: any, index: number) => {
                let obj: any = {};
               
                res.values.forEach((tdRes: any, tdIndex: number) => {
                    const colDetails = resData.gridColumns[tdIndex];
                    obj[colDetails.name.trim()] = parseDataByType(tdRes, colDetails.dataType);
                });
                
                processedData.push(obj);
            })
        }
        
        return { ...resData, gridRows: processedData }

    } catch (e) {
        console.log(e)
    }
}

export default { processDataForKendoGrid }