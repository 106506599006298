import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { pickListDataPopup } from "../store/actions";
import { useEffect } from "react";

const SelectPicker = (props: any) => {
    const dispatch = useDispatch();
    const pickList = useSelector((state: any) => state.selectPickCM.data);

    useEffect(() => {
        // Fetch Information pickList
        try {
            dispatch(
                pickListDataPopup({
                    token: props.token,
                    param: {
                        dspId: props.dspId,
                        // oneViewReference: props.items.selectedCaseReference,
                        controlId: props.items.controlId,
                    },
                    url: props.items.apiPicklistEndpoint.endpointUrl.replace("/api", ""),
                })
            );
        } catch (e: any) {
            toast.error(e.message);
        }
    }, []);

    return (
        <div className="column" key={props.items.controlId}>
            <h6 className="involve-case-title">{props.items.label}</h6>
            <div className="involve-case-container">
                <select
                    disabled={!props.items.isEditable}
                    className="involve-case"
                    name={props.items.name}
                    key={props.items.controlId}
                    onChange={props.handleChange}
                    value={props.formData[props.items.name]}
                >
                    {pickList?.values?.map((option: any) => (
                        <option value={option.valueToStore} key={option.valueToStore}>
                            {option.textToDisplay == "" ? "select" : option.textToDisplay}
                        </option>
                    ))}
                </select>
            </div>
        </div>)
}
export default SelectPicker;