import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { addCaseDetailAll, addEditPopup, addOutcomesData, deleteOutcomeData, resetAddEditPopup, resetAddOutcomesData, resetCaseDetailAll, resetDeleteOutcomeData, downloadAssets, resetDownloadAssets } from "../store/actions";
import { Button } from "../../../components";
import { GridLoader } from "../../community-assets/component/loader";
import KendoGrid from "../../../components/containers/kendo-grid"
import AddNotePopup from "./addNotePopup";
import caseSummaryService from "../services/case-summary-service";
import CustomAction from "../../dashboard/component/customAction";
import { API_ENDPOINT } from "../../../config/ApiConstants";
interface PageState {
    skip: number,
    take: number
}
// Initial pagination state
const initialDataState: PageState = { skip: 0, take: 25 };


const CasedetailsGrid: React.FC<any> = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [addOutcomeModel, setAddOutcomeModel] = useState<Boolean>(false);
    const [addOutcomeModelDataState, setAddOutcomeModelDataState] = useState<any>();
    const [caseDetailListData, setCaseDetailListData] = useState([]);
    
    const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number | null>(1);
    const [dataState, setDataState] = useState<any>(initialDataState);
    const [gridData, setGridData] = useState<any>(null);
    const [isUpload, setIsUpload] = useState<boolean>(false);
    const [recordId, setRecordId] = useState<any>(null);

    const addEditOutcomeDataConfig: any = useSelector<any>((state) => state.addEditCM);
    const caseDetailAlldata = useSelector((state: any) => state.caseDetailAllCM);
    const getEditDetailData = useSelector((state: any) => state.getEditOutcomeCM);
    const addPopupData = useSelector((state: any) => state.addOutcomeCM);
    const deleteDetailData = useSelector((state: any) => state.deleteDetailDataCM);
    const downloadAssetsData = useSelector((state: any) => state.downloadAssets);
    

    

    useEffect(() => {
        // Fetch Information addCaseDetailAll
        fetchCaseDetailsData();
    }, [])

    const fetchCaseDetailsData = () => {
        setCurrentPage(1);
        try {
            dispatch(
                addCaseDetailAll({
                    token: props.token,
                    param: {
                        dspId: props.dsp,
                        oneViewReference: props.selectedCaseReference,
                        controlId: props.tabs.listView.listViewId,
                        pageNumber: currentPage,
                        pageSize: recordsPerPage
                    }
                })
            );
        } catch (e: any) {
            toast.error(e.message);
        }
    }

    useEffect(() => {
        // Process received addOutcomeModel data
        try {
            if (addEditOutcomeDataConfig.isSuccess && addEditOutcomeDataConfig.data !== null) {

                console.log("addEditOutcomeData: ", addEditOutcomeDataConfig.data);



                setAddOutcomeModelDataState(addEditOutcomeDataConfig.data);
                dispatch(resetAddEditPopup());
            } else if (addEditOutcomeDataConfig.isError) {
                toast.error(addEditOutcomeDataConfig?.data?.error?.data);
                dispatch(resetAddEditPopup());
                // setAddOutcomeModel(false);
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [addEditOutcomeDataConfig]);

    useEffect(() => {
        try {
            if (addPopupData.isSuccess && addPopupData.data !== null) {
                // toast.success("Case Details add successful");
                // setAddOutcomeModel(false);

                console.log("addEditOutcomeData: ", addPopupData.data);
                setIsUpload(true)
                setRecordId(addPopupData.data.id)
                fetchCaseDetailsData();
                dispatch(resetAddOutcomesData());
            } else if (addPopupData.isError) {
                toast.error(addPopupData?.data?.error);
                setAddOutcomeModel(false);
                dispatch(resetAddOutcomesData());
            }
            // props.fetchCaseDetailsData();
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [addPopupData])

    useEffect(() => {
        try {
            if (deleteDetailData.isSuccess && deleteDetailData.data !== null) {
                // toast.success("Case Details data delete successful");
                dispatch(resetDeleteOutcomeData());
                fetchCaseDetailsData();
            } else if (deleteDetailData.isError) {
                toast.error(deleteDetailData?.data?.error);
                fetchCaseDetailsData();
                dispatch(resetDeleteOutcomeData());
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [deleteDetailData])


    useEffect(() => {
        console.log("downloadAssetsData: ", downloadAssetsData);
        try {
            if (downloadAssetsData.isSuccess && downloadAssetsData.data !== null) {
                // toast.success("Case Details data delete successful");
                console.log("downloadAssetsData: ", downloadAssetsData);
                window.open(downloadAssetsData.data, "_blank");
                dispatch(resetDownloadAssets());
                fetchCaseDetailsData();
            } else if (downloadAssetsData.isError) {
                toast.error(downloadAssetsData?.data?.error);
                dispatch(resetDownloadAssets());
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [downloadAssetsData])

    const _addCaseDetail = () => {
        setAddOutcomeModel(true);
        setIsUpload(false);
        setRecordId(null);
    }

    useEffect(() => {
        if (addOutcomeModel) {
            try {
                // Fetch Information add_edit_cofig
                dispatch(
                    addEditPopup({
                        token: props.token,
                        param: {
                            dspId: props.dsp,
                            oneViewReference: props.selectedCaseReference,
                            controlId: props.tabs.listView.listViewId,
                        },
                    })
                );
            } catch (e: any) {
                toast.error(e.message);
            }
        }
    }, [addOutcomeModel]);

    const __submitIgPluginAuth = (type: string, data: any) => {
        try {
            if (type === "closeForm") {
                setAddOutcomeModel(false);
            } else if (type === "formSubmit") {

                /**
                 * Dispatch action to Add outcome data
                 **/
                let convertedArray: any = [];
                for (const key in data) {
                    if (data[key] !== '') {
                        convertedArray.push({
                            "controlName": key,
                            "values": data[key]
                        })
                    }
                }
                try {
                    dispatch(
                        addOutcomesData({
                            token: props.token,
                            param: {
                                dspId: +props.dsp,
                                oneViewReference: props.selectedCaseReference,
                                controlId: addOutcomeModelDataState.popupForm.controls[0].associatedControlId,
                            },
                            body: convertedArray,
                        })
                    );
                } catch (e: any) {
                    toast.error(e.message);
                }

            }
        } catch (e: any) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (caseDetailListData !== caseDetailAlldata.data) {
            if (caseDetailAlldata.isSuccess && caseDetailAlldata.data !== null) {
                // setCaseDetailListData(caseDetailAlldata.data)
                const masterData = caseDetailAlldata.data;
                const paging = masterData.paging

                const data = caseSummaryService.processDataForKendoGrid(masterData)

                setDataState({
                    skip: (paging?.currentPage * recordsPerPage) - recordsPerPage,
                    take: recordsPerPage
                })

                setCaseDetailListData(data)
                setGridData(data);
                // setGridDataRows({ data: caseDetailAlldata?.data?.gridRows, total: paging?.totalCount })

                dispatch(resetCaseDetailAll())
            } else if (caseDetailAlldata.isError) {
                console.error(caseDetailAlldata)
                toast.error(caseDetailAlldata.data.error.message)
                dispatch(resetCaseDetailAll())
            }
        }

    }, [caseDetailAlldata])


    const __onCustomAction = (res: any) => {
        try {
            switch (res.action) {
                case "delete":
                    dispatch(deleteOutcomeData({
                        token: props.token,
                        param: {
                            controlId: props.tabs.listView.listViewId,
                            dspId: props.dsp,
                            id: res.data.Id
                        }
                    }))
                    break;
                default:
                    break;

            }
            setAddOutcomeModel(false);
            setAddOutcomeModelDataState(null);
        } catch (res) {
            console.log(res);
        }
    }

    const __onPageChange = (res: any) => {
        setRecordsPerPage(res.page.take)
        let page = (res.page.skip / res.page.take) + 1
        setCurrentPage(page)
    }

    const __downloadAsset = (url: string) => {
        try {
            dispatch(downloadAssets({
                token: props.token,
                url: url
            }))
        } catch (e: any) {  
            toast.error(e.message);
        }
        
    }


    const __customCell = (res: any) => {
            return {
                "DocFilePath": (data: any) => {
                    return <td>{
                        data.dataItem.DocFilePath.length > 0 ?
                            data.dataItem.DocFilePath.map((item: any, index: number) => {
                                return <button key={index}
                                    onClick={() => __downloadAsset(item.link)}
                                    className="btn btn-link d-block"
                                >{item.name}</button>
                            }) : null
                    }</td>
                }
            }
        }

    const renderOutcomeList = () => {
        return (
            gridData !== null
                ? (
                    <KendoGrid
                        gridData={caseDetailListData}
                        pageSize={recordsPerPage}
                        getEditDetailData={getEditDetailData}
                        token={props.token}
                        dsp={props.dsp}
                        selectedCaseReference={props.selectedCaseReference}
                        onPageChange={(res: any) => __onPageChange(res)}
                        // fetchCaseNotesData={fetchCaseDetailsData}
                        dataState={dataState}
                        onHiddenCount={(e: any) => { 
                        }}
                        expandField=""

                        isSelectable={false}
                        isCustomAction={true}
                        customeActionLayout={(resData: any) => <CustomAction
                            data={resData}
                            isDetails={false}
                            isEditButton={false}
                            onClick={(e: any) => __onCustomAction(e)}
                        />}
                        customCell={(res: any) => __customCell(res)}
                    />) : null
        );

    }


    return (
        <div className="outcome-grid">
            <div className="filter-content pos-relative">
                <div className="filter-header">
                    <h3></h3>
                    <div style={{ justifyContent: "center", alignContent: "end" }}>
                        <Button
                            style={{ backgroundColor: "#ffffff", border: "none", color: "#5464B0", padding: "0.625rem 1.188rem", fontWeight: "600", borderRadius: "0.625rem" }}
                            key={""}
                            title={t("ADDNOTES")}
                            type={"button"}
                            onClick={() => _addCaseDetail()}
                        />
                    </div>
                </div>
            </div>
            {caseDetailAlldata.isFetching ? <GridLoader /> : renderOutcomeList()}
            {addOutcomeModel ? (
                <AddNotePopup
                    token={props.token}
                    dspId={props.dsp}
                    data={addOutcomeModelDataState}
                    isFetching={false}
                    isUpload={isUpload}
                    recordId={recordId}
                    uploadURL={`${API_ENDPOINT}/case-details/upload-document?controlId=30&recordId=${recordId}&oneViewReference=${props.selectedCaseReference}&dspId=${props.dsp}`}
                    onSubmit={(type: string, res: any) => {
                        if (res === null) {
                            __submitIgPluginAuth("closeForm", res)
                        } else {
                            __submitIgPluginAuth(type, res)
                        }
                    }}
                />
            ) : null}
        </div>
    );
};

export default CasedetailsGrid;
